import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Auth0AuthenticationServiceLazy from './auth0-authentication.service.lazy';
import { HttpService } from '../api/http.service';
import { useRouter } from 'next/router';
import { useWalletConnect } from './connect-wallet';

const Context = createContext<{
  userInfo: any;
  accessToken: string | null;
  authInProgress: boolean;
  notAuthenticated: boolean;
  error: any;
  login: ({ promptOnFail, redirectUri }: { promptOnFail: boolean; redirectUri: string }) => Promise<void>;
  auth0Login: ({
    promptOnFail,
    redirectUri,
    maxAttempts,
  }: {
    promptOnFail?: boolean;
    redirectUri?: string;
    maxAttempts?: number;
  }) => Promise<void>;
  logout: (url: string) => Promise<void>;
  walletLogin: () => void;
  loggedInViaWallet: boolean;
  walletAccount: any;
  combinedAuthState: 'in-progress' | 'authenticated' | 'not-authenticated';
  checkAuth: () => Promise<void>;
}>({
  userInfo: null,
  accessToken: null,
  authInProgress: false,
  notAuthenticated: false,
  error: null,
  login: async ({ promptOnFail, redirectUri }) => {},
  auth0Login: async ({
    promptOnFail,
    redirectUri,
    maxAttempts = 1,
  }: {
    promptOnFail?: boolean;
    redirectUri?: string;
    maxAttempts?: number;
  }) => {},
  logout: async (url) => {},
  walletLogin: () => {},
  loggedInViaWallet: false,
  walletAccount: null,
  combinedAuthState: 'in-progress',
  checkAuth: async () => {},
});

export const AuthContext = Context;

export function useAuth() {
  return useContext(Context);
}

export function withAuth(WrappedComponent) {
  return (props) => {
    const router = useRouter();
    const {
      loggedInViaWallet,
      account: walletAccount,
      openModal: walletLogin,
      disconnect: logoutWallet,
      walletAuthState,
      checkWalletConnect,
    } = useWalletConnect();
    const [authState, setAuthState] = useState({
      userInfo: null,
      authInProgress: false,
      notAuthenticated: false,
      accessToken: null,
      error: null,
    });
    const combinedAuthState = useMemo(() => {
      console.log('Auth: calculate combinedAuthState');

      if (walletAuthState === 'authenticated') {
        return 'authenticated';
      }

      if (authState.userInfo && !authState.authInProgress) {
        return 'authenticated';
      }

      if (
        (!authState.userInfo && !authState.notAuthenticated) ||
        authState.authInProgress ||
        walletAuthState === 'check-in-progress'
      ) {
        return 'in-progress';
      }

      /*if (walletAuthState === 'not-checked') {
        return 'not-authenticated';
      }*/
      console.log('Auth: combinedAuthState not authenticated');

      return 'not-authenticated';
    }, [authState.userInfo, authState.authInProgress, authState.notAuthenticated, walletAuthState]);

    console.log('Auth: auth.context state:', {
      authState,
      walletAuthState,
      combinedAuthState,
    });

    const auth0Login = useCallback(
      async ({
        promptOnFail,
        redirectUri,
        maxAttempts = 1,
      }: {
        promptOnFail?: boolean;
        redirectUri?: string;
        maxAttempts?: number;
      }) => {
        // Set authInProgress to true once before all attempts
        setAuthState({ ...authState, authInProgress: true });

        let lastError;

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
          try {
            console.log(`Auth: Logging in to auth0 (attempt ${attempt}/${maxAttempts})`);

            const accessToken = await Auth0AuthenticationServiceLazy.login();
            console.log('Logged in to auth0 successfully');
            HttpService.setGlobalHeader({ Authorization: `Bearer ${accessToken}` });
            const userInfo = await Auth0AuthenticationServiceLazy.getUserInfo();
            console.log('auth: userInfo', userInfo);
            // Success - update state and return result
            setAuthState({ ...authState, authInProgress: false, userInfo, accessToken });

            return;
          } catch (e) {
            console.log(`Auth: Login Error (attempt ${attempt}/${maxAttempts})`);
            console.error(e);
            lastError = e;

            // If we have more attempts remaining, wait and try again
            if (attempt < maxAttempts) {
              console.log('Waiting 500ms before next attempt');
              await new Promise((resolve) => setTimeout(resolve, 500));
            }
          }
        }

        // All attempts failed - handle failure
        if (promptOnFail) {
          try {
            console.log('Auth: All login attempts failed, trying with prompt');
            await Auth0AuthenticationServiceLazy.loginWithPrompt(redirectUri);
          } catch (e) {
            setAuthState({
              ...authState,
              authInProgress: false,
              error: lastError,
              notAuthenticated: true,
              accessToken: null,
              userInfo: null,
            });
          }
        } else {
          setAuthState({
            ...authState,
            authInProgress: false,
            error: lastError,
            notAuthenticated: true,
            accessToken: null,
            userInfo: null,
          });
        }

        return null;
      },
      [authState],
    );

    const checkAuth0Auth = async (maxAttempts = 2) => {
      // Using the updated auth0Login with maxAttempts parameter
      await auth0Login({
        promptOnFail: false,
        maxAttempts,
      });
    };

    (global as any).checkAuth0Auth = checkAuth0Auth;

    const checkAuth = async () => {
      if ((global as any)._check_auth_in_progress) {
        console.log('Auth: checkAuth is already in progress. Cancelling.');
        return;
      }
      try {
        (global as any)._check_auth_in_progress = true;
        await Promise.all([checkAuth0Auth(1), checkWalletConnect()]);
      } finally {
        (global as any)._check_auth_in_progress = false;
      }
    };

    (global as any).checkAuth = checkAuth;

    useEffect(() => {
      //if (!authState.userInfo && !authState.notAuthenticated && !authState.authInProgress) {
      console.log('Auth: silently login the user');
      checkAuth0Auth();
      //}
    }, []);

    const login = useCallback(
      async ({ promptOnFail, redirectUri }) => {
        // redirect to /auth/login
        await router.push('/auth/login?redirectUri=' + encodeURIComponent(redirectUri));
      },
      [authState],
    );
    const logout = useCallback(
      async (url) => {
        if (loggedInViaWallet) {
          await logoutWallet();
        }
        if (authState?.userInfo) {
          await Auth0AuthenticationServiceLazy.logout(url);
        }
      },
      [authState],
    );

    return (
      <Context.Provider
        value={{
          ...authState,
          auth0Login,
          walletLogin,
          login,
          logout,
          loggedInViaWallet,
          walletAccount,
          combinedAuthState,
          checkAuth,
        }}
      >
        <WrappedComponent {...props} />
      </Context.Provider>
    );
  };
}
